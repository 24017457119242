<template>
  <div class="auth-wrapper auth-v2">
    <b-overlay no-wrap :show="showOverlay" />
    <b-row class="auth-inner m-0">
      <b-link class="brand-logo">
        <b-img
          :src="heroleadsLogo"
          alt="Heroleads Logo"
        />
        <h2 class="brand-text text-primary ml-1 mt-2">
          HeroVisionX
        </h2>
      </b-link>
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="imageUrl" alt="Image" />
        </div>
      </b-col>
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            Welcome to HeroVisionX! 👋
          </b-card-title>
          <b-card-text class="mb-2">
            Please log in to your account and start the adventure
          </b-card-text>
          <validation-observer
            ref="loginForm"
            #default="{invalid}"
          >
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <b-form-group
                label="Email"
                label-for="email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    placeholder="johndoe@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="password">Password</label>
                  <b-link :to="{ name: 'heroai-client-forget-password' }" class="back-to-login-link">
                    <small>Forgot Password?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
                class="btn-sign-in"
                @click="validationForm"
              >
                Sign in
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import axios from '@axios'
import AxiosConfig from '@/services/AxiosConfigs'
import CsrfService from '@/services/CsrfService'
import ErrorService from '@/services/ErrorService'
import SweetAlert from '@/services/SweetAlert'
import Toastification from '@/services/Toastification'
import moment from 'moment'
import {
  BButton,
  BCardText,
  BCardTitle,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BImg, BInputGroup, BInputGroupAppend,
  BLink,
  BOverlay,
  BRow,
} from 'bootstrap-vue'

export default {
  components: {
    BInputGroupAppend,
    BInputGroup,
    BForm,
    BCol,
    BFormGroup,
    BOverlay,
    BCardTitle,
    BCardText,
    BImg,
    BRow,
    BFormInput,
    BButton,
    BLink,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      // Overlay
      showOverlay: false,

      userEmail: '',
      password: '',
      status: '',
      // validation rules
      required,
      email,
      heroleadsLogo: require('@/assets/images/logo/heroleads-logo.png'),
      image: require('@/assets/images/pages/login-v2.svg'),
    }
  },
  computed: {
    imageUrl() {
      if (this.$store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.image = require('@/assets/images/pages/login-v2-dark.svg')
        return this.image
      }
      return this.image
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  async created() {
    await CsrfService.get()
  },
  methods: {
    async validationForm() {
      const axiosConfig = AxiosConfig.getJsonWithoutToken()
      const validationResult = await this.$refs.loginForm.validate()

      if (validationResult) {
        this.showOverlay = true

        const body = {
          email: this.userEmail,
          password: this.password,
          page: 'HeroVisionX',
        }

        try {
          const response = await axios.post('login', body, axiosConfig)

          const payload = {
            sanctumToken: response.data.token,
            loginData: response.data.data,
            loginTime: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          }

          await this.prepareLogin(payload)
        } catch (error) {
          await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
        }

        this.showOverlay = false
      }
    },
    async prepareLogin(payload) {
      // สำหรับ HeroVision ให้ทำการซ่อนเมนูหลัก
      this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)

      // ทำการบันทึกข้อมูลของผู้ใช้งานเก็บไว้ใน Storage
      await this.$store.commit('heroAiAuthentications/doLogin', payload)
      // ทำการแปลง Permission ที่ได้รับมาจาก Backend ให้เป็นรูปแบบที่ Frontend เข้าใจ โดยข้อมูล Permission อยู่ใน loginData
      await this.$store.dispatch('heroAiAuthentications/convertPermissions')
      // ทำการอัพเดต Permission ที่แปลงรูปแบบแล้วเข้าไปใน $ability เพื่อใช้ในการตรวจสอบสิทธิ์ทั้งระบบ
      this.$ability.update(this.$store.getters['heroAiAuthentications/getPermissions'])

      // แสดง Toast ยินดีต้อนรับ
      const fullName = this.$store.getters['heroAiAuthentications/fullName']
      const toastObject = Toastification.getContentSuccess(`Welcome to HeroAIX, ${fullName}`)
      this.$toast(toastObject)

      // ถ้าหากมีข้อมูล Route Name สำหรับการ Redirect ให้ทำเข้าไปยัง URL ดังกล่าวแทน
      // Redirect จะถูกสร้างจาก router/index.js
      if (this.$route.query.redirect) {
        await this.$router.push({ path: this.$route.query.redirect })
      } else {
        await this.$router.push({ name: 'herovision-index' })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.btn-sign-in, .btn-sign-in:active, .btn-sign-in:focus {
  background-color: #ff3e00 !important;
  border-color: #ff3e00 !important;
  border-radius: 50px;
}

.back-to-login-link {
  color: #ff3e00 !important;
}
</style>
